import React from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Segment } from "semantic-ui-react";
import Helmet from "react-helmet";
import rehypeReact from "rehype-react";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import { media } from "../utils/mediaSizr";
import * as colors from "../utils/colors";

const ContainerWidthStyleDesktop = `
	width: 50vw;
	margin: 0 -25vw 0 -25vw;
	padding: 1.5rem 0 1.5rem 0;
`;
const ContainerWidthStyleTablet = `
	width: 80vw;
	margin: 0 -40vw 0 -40vw;
	padding: 1rem 0 1rem 0;
`;

const ContainerWidthStyleMobile = `
	width: 98vw;
	margin: 0 -49vw 0 -49vw;
	padding: 0.8rem 0 0.8rem 0;
`;

const ImgContainer = styled.div`
	left: 50%;
	right: 50%;
	position: relative;

	${media.desktop`
		${ContainerWidthStyleDesktop}
	`}
	${media.tablet`
		${ContainerWidthStyleTablet}
	`}
	${media.mobile`
		${ContainerWidthStyleMobile}
	`}

	/*image ref */
	p {
		font-size: 0.6rem;
		text-align: right;
	}
`;

const BackIconContainer = styled.div`
	text-align: center;
	padding: .5rem 0 .5rem 0;
`;

const QuoteContainer = styled.div`
	font-style: italic;

	.icon {
		vertical-align: super;
	}

	p {
		font-style: normal;
		font-size: 0.8rem;
		display: inline;
	}
`;

const BlogPostContainer = styled.div`

	img {
		max-width: 100%;
		display: block;
		margin: 0 auto;
	}

	dl.algorithm {
		padding: 0 0 1rem 1rem;
		border-top:.1rem solid;
		border-bottom: .1rem solid;
		font-family: "Fira Code Light" !important;
		white-space: pre;
		tab-size: 4;
		overflow: auto;
		-o-tab-size: 4;

		b {
			font-family: "Fira Code" !important;
			font-weight: 600;
		}
	}
	code {
		font-family: "Fira Code Light" !important;
	}
 	.gatsby-highlight {
		left: 50%;
		right: 50%;
		position: relative;

		${media.desktop`
			${ContainerWidthStyleDesktop}
		`}
		${media.tablet`
			${ContainerWidthStyleTablet}
		`}
		${media.mobile`
			${ContainerWidthStyleMobile}
	`	}
	}
	p a :not(.gatsby-resp-image-link), li a {
		color: ${colors.ui.solid};
		text-decoration: none;
		border-bottom: 1px solid ${colors.ui.alpha(.5)};
		transition: background-color .3s ease-in-out;

	:hover {
		color: ${colors.text.solid};
		border-bottom: 2px solid transparent;
		background-color: ${colors.ui.alpha(.3)};
	}
}
	blockquote {
		font-style: italic;
		padding-left: 1rem;
		border-left: 0.3rem solid ${colors.ui.alpha(.3)};
	}
`;

const renderAst = new rehypeReact({
	createElement: React.createElement
}).Compiler;

class BlogPost extends React.Component {

	goBack = () => navigate("/blog");

	render() {
		const post = this.props.data.markdownRemark;
		const {title, date} = post.frontmatter;
		return (
			<Layout {...{title, date}}>
				<Helmet {...{title}}/>
				<Segment padded>
					<BlogPostContainer>
						{post.frontmatter.quote && (
							<QuoteContainer>
								<Icon name="quote left" size="tiny"/>
								{post.frontmatter.quote}
								<Icon name="quote right" size="tiny"/>
								<p> - {post.frontmatter.quoteRef}</p>
							</QuoteContainer>

						)}
						{post.frontmatter.image && (
							<ImgContainer>
								<Img fluid={post.frontmatter.image.childImageSharp.fluid} />
								<p>image courtesy {post.frontmatter.imageAuthorLink}&nbsp;</p>
							</ImgContainer>
						)}
						<div/>
						{renderAst(post.htmlAst)}

					</BlogPostContainer>
				</Segment>
				<BackIconContainer
				>
					<Icon link
						name="angle left"
						size="large"
						onClick={this.goBack}
					/>
				</BackIconContainer>

			</Layout>
		);
	}
}

BlogPost.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object
};
export default BlogPost;

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			htmlAst
			frontmatter {
				title
				date(formatString: "dddd, MMM D, YYYY")
				quote
				quoteRef
				imageAuthorLink
				image {
					childImageSharp {
						resize(width: 1500, height: 1500) {
							src
						}
						fluid(maxWidth: 786) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;